.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to bottom right, #0b8c8d, #42b6bc);
  }
form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  label {
    margin-top: 10px;
    font-weight: bold;
  }
  
  input {
    margin-top: 5px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 250px;
    font-size: 1rem;
  }
  
  button[type="submit"] {
    margin-top: 10px;
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    background-color: #0b8c8d;
    color: white;
    font-size: 1rem;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #bc426b;
  }