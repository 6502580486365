.back {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
    padding: 0 10px;
  }
  
  .back-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 15px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #cfdfc5;
    background-color: #42b6bc;
    cursor: pointer;
    margin-right: 20px;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
  }
  
  .back-button:hover {
    background-color: #bc426b;
  }
  
  .back-button:last-child {
    margin-right: 0;
  }
  