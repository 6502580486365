.landing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to bottom right, #0b8c8d, #42b6bc);
    color: #cfdfc5;
  }
  
  .landing-page-box {
    padding: 20px;
    border-radius: 20px;
    background-color: #0b8c8d;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 620px;
  }

  .navigation {
    position: absolute;
    top: 0;
    left: 0;
  }
