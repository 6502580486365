.death-roll {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  color: #cfdfc5;
}

.tabs {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 0 10px;
  height: 90%;
  width: 15%;
  margin-top: 10px;
}

.tab {
  background-color: #42b6bc;
  border-radius: 10px;
  margin-bottom: 5px;
  padding: 10px;
  transition: width 0.5s;
  cursor: pointer;
}

.tab h2 {
  margin: 0;
  text-align: center;
}

.tab.open {
  justify-content: flex-start;
}

.tab:not(.open):hover {
  background-color: #bc426b;
}

.back {
  position: absolute;
  top: 0;
  left: 0;
}

.close-container{
  display: flex;
  justify-content: center;
  margin-top: 1%;
}

.close-button, .roll-button {
  background-color: #32aaaf;
  color: #cfdfc5;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin-top: 2%;
}

.roll-button {
  background-color: #42b6bc;
  color: #cfdfc5;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin-top: 2%;
}

.close-button:hover, .reset-button, .start-button:hover, .roll-button:hover, .add-button:hover {
  background-color: #bc426b;
}

.start-container{
  display: flex;
  justify-content: center;
  margin-top: 1%;
}

.start-button, .reset-button {
  background-color: #42b6bc;
  color: #cfdfc5;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin-top: 2%;
}

.slider {
  -webkit-appearance: none;
  height: 5px;
  width: 94%;
  background: #ddd;
  outline: none;
  margin-bottom: 10px;
}

/* default style */
.number-input {
  width: 94%;
  max-width: 400px; /* set a maximum width to prevent input fields from becoming too large on larger screens */
  margin-bottom: 10px;
  background-color: #42b6bc;
  border-color: #fedab2;
  color: #fedab2;
}

.text-input {
  width: 65%;
  max-width: 400px; /* set a maximum width to prevent input fields from becoming too large on larger screens */
  margin-bottom: 10px;
  margin-right: 4px;
  background-color: #42b6bc;
  border-color: #fedab2;
  color: #fedab2;
}

input[type="text"]:focus, input[type="number"]:focus {
  border-color: #bc426b;
  outline: none; /* removes the default outline on focus */
}

/* adjust width for screens smaller than 768px */
@media (max-width: 767px) {
  .text-input{
    width: 75%; /* adjust the width for even smaller screens */
  }
  .number-input {
    width: 90%; /* adjust the width for even smaller screens */
  }
}

/* adjust width for screens smaller than 480px */
@media (max-width: 479px) {
  .text-input{
    width: 75%; /* adjust the width for even smaller screens */
  }
  .number-input {
    width: 90%; /* adjust the width for even smaller screens */
  }
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #42b6bc;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #42b6bc;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .tabs {
    width: 100%;
    height: auto;
    position: static;
    margin-bottom: 10px;
  }
  
  .tab {
    width: 100%;
  }
  
  .close-container {
    flex-direction: column;
    align-items: center;
  }
}

.remove-button {
  background-color: #0b8c8d;
  color: #cfdfc5;
  padding: 1px 5px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin-top: 2%;
  margin-right: 2%;
}

body {
  background: linear-gradient(to bottom right, #0b8c8d, #42b6bc);
  min-height: 100vh;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.add-button {
  background-color: #32aaaf;
  color: #cfdfc5;
  padding: 8px 20px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
}

.history {
  display: flex;
  justify-content: center;
}